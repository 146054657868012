import React, { useState, useEffect } from 'react'
import interactionPlugin from '@fullcalendar/interaction'
import ptLocale from '@fullcalendar/core/locales/pt-br'
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { getDoctorSchedulings } from 'services/doctor'

import { Card, Col, Container, Row } from 'reactstrap'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import ComponentBack from 'components/ComponentBack'
import Loading from 'components/Loading'

import OverlaySchenduling from './components/OverlaySchenduling'

import * as S from './styles'
import AllModal from './components/AllModal'

const SchedulingsDoctor = () => {
  const [modalChangeOpen, setModalChangeOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [modalType, setModalType] = useState<'Confirm' | 'Change' | 'Delete'>(
    'Confirm'
  )
  const [newScheduling, setNewScheduling] = useState<any>()
  const [schedulings, setSchedulings] = useState<any>([])
  const [days, setDays] = useState({
    start: moment().startOf('week').format('YYYY-MM-DD'),
    end: moment().endOf('week').format('YYYY-MM-DD'),
  })

  const { state }: any = useLocation()
  const screen = window.innerWidth

  const getSchedulings = async () => {
    setLoading(true)
    const response = await getDoctorSchedulings(state.doctorCard?.id, days)

    if (response.status) {
      setSchedulings(response?.item || [])
    } else {
      setSchedulings([])
    }
    setLoading(false)
  }

  useEffect(() => {
    getSchedulings()
  }, [days.start])

  const filterSchedulings = () =>
    schedulings?.map((item: any) => ({
      id: item?.id,
      title: item.user_name,
      specialty_id: item?.specialty_name?.[0]?.id,
      start: moment(item?.from_date_time).utc().format('YYYY-MM-DD HH:mm'),
      end: moment(item?.to_date_time).utc().format('YYYY-MM-DD HH:mm'),
      quantity: item?.quantity,
      overlap: true,
      groupId: `grup${item?.id}`,
      constraint: 'queryEventsNone',
    }))

  const handleDeleteScheduling = (info: any) => {
    setNewScheduling({
      ...newScheduling,
      name: info.event.title,
      id: info.event.id,
      oldDay: info.event?.start,
      oldTime: moment(info.event?.start).utc().format('HH:mm'),
      oldEndTime: moment(info.event?.end).utc().format('HH:mm'),
    })
    setModalChangeOpen(true)
    setModalType('Delete')
  }

  const handleDatesSet = (info: any) => {
    const start = moment(info.start).format('YYYY-MM-DD')
    const end = moment(info.end).format('YYYY-MM-DD')
    setDays({
      start,
      end,
    })
  }

  return (
    <>
      {loading && <Loading title="Aguarde..." />}
      <HeaderNeutro />
      <ComponentBack />
      {modalChangeOpen && (
        <AllModal
          doctorId={state.doctorCard?.id}
          newScheduling={newScheduling}
          setNewScheduling={setNewScheduling}
          setModalOpen={setModalChangeOpen}
          schedulings={schedulings}
          setSchedulings={setSchedulings}
          getSchedulings={getSchedulings}
          modalType={modalType}
          setModalType={setModalType}
        />
      )}
      <Container className="m-0 p-0 mt-3" fluid>
        <Card className="shadow ">
          <Row>
            <Col className=" m-3">
              <h3>{`Consultas agendadas com ${state.doctorCard?.name}`}</h3>
            </Col>
          </Row>
          <div
            style={{
              borderBottom: '1px solid #e6e1f1 ',
            }}
          />
          <S.CalendarView>
            <FullCalendar
              plugins={[timeGridPlugin, interactionPlugin]}
              initialView={screen > 768 ? 'timeGridWeek' : 'timeGridDay'}
              locale={ptLocale}
              slotDuration="00:15:00"
              slotLabelInterval="00:15:00"
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
              }}
              slotMinTime="08:00:00"
              slotMaxTime="24:00:00"
              // validRange={{ start: new Date() }}
              dayHeaderFormat={{
                weekday: 'long',
                month: 'numeric',
                day: 'numeric',
              }}
              timeZone="America/Sao_Paulo"
              events={[...filterSchedulings()]}
              editable={false}
              firstDay={1}
              titleFormat={
                screen > 768
                  ? { day: 'numeric', month: 'long' }
                  : { day: 'numeric', month: 'numeric' }
              }
              datesSet={handleDatesSet}
              allDaySlot={false}
              eventContent={(info: any) => {
                if (info.event.groupId !== 'queryEventsNone') {
                  return (
                    <>
                      <OverlaySchenduling
                        specialty={info.event.title}
                        day={info.event.start}
                        uuid={info.event.id}
                        startTime={moment(info.event.start)
                          .utc()
                          .format('HH:mm')}
                        endTime={moment(info.event.end).utc().format('HH:mm')}
                        handleClick={() => {
                          setNewScheduling({
                            ...newScheduling,
                            specialty_id: info.event.extendedProps.specialty_id,
                            quantity: info.event.extendedProps.quantity,
                            name: info.event.title,
                            id: info.event.id,
                            oldDay: info.event?.start,
                            oldTime: moment(info.event?.start)
                              .utc()
                              .format('HH:mm'),
                            oldEndTime: moment(info.event?.end),
                          })
                          setModalChangeOpen(true)
                          setModalType('Change')
                        }}
                        handleDelete={() => handleDeleteScheduling(info)}
                      />
                    </>
                  )
                }
                return null
              }}
            />
          </S.CalendarView>
        </Card>
      </Container>
    </>
  )
}

export default SchedulingsDoctor
