import React, { useEffect, useState, useMemo } from 'react'
import api from 'configs/api'
import { cpfCnpjMask } from 'js-essentials-functions'
import { FormikProvider, useFormik } from 'formik'

import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from 'reactstrap'
import PaginationComponent from 'components/PaginationComponent'
import Loading from 'components/Loading'
import { handleEnterPress } from 'utils/enterPress'

import ReactDatetime from 'react-datetime'

import { alertWarning, alertDanger, alertSuccess } from 'utils/toast'
import * as yup from 'yup'
import moment from 'moment'

const generateStatus = (item: string) => {
  const statusInvoices: any = {
    paid: 'Pago',
    pending: 'Pendente',
    canceled: 'Cancelado',
    expired: 'Vencido',
  }

  return statusInvoices[item] || 'Não gerado'
}

const schemaRegister = yup.object().shape({
  due_date: yup.string().required('Informe a data de vencimento!'),
  amount: yup
    .number()
    .moreThan(0, 'O valor deve ser positivo')
    .required('Informe o valor!'),
})

const BilletMonth = () => {
  const [companies, setCompanies] = useState<any[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState('')
  const [month, setMonth] = useState(new Date())

  // Modal Remover boleto
  const [modalRemove, setModalRemove] = useState(false)
  const [removeId, setRemoveId] = useState('')

  // Modal add boleto
  const [modalAdd, setModalAdd] = useState(false)
  const [companySelected, setCompanySelected] = useState<any>()

  // Paginação
  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10

  const initialValues = useMemo(
    () => ({
      due_date: '',
      amount: '',
      tax: '',
      fine: '',
      observation: '',
    }),
    []
  )

  const getCompaniesBillet = async (
    nameCompany = name,
    numberPage = page,
    monthSearch = month
  ) => {
    const formatMonth = moment(monthSearch).format('YYYY-MM')
    setLoading(true)
    try {
      // const response = await api.get(
      //   `manager/financial/company/billet?page=${numberPage}&take=${limit}&name=${nameCompany}&month=${formatMonth}`
      // )
      // const datas = response.data.items.map((data: any) => ({
      //   id: data.id,
      //   name: data.legal_nature,
      //   cnpj: data.national_registration,
      //   status_billet: data?.billet?.status,
      //   url_billet: data.billet?.billet_url,
      //   id_billet: data.billet?.id,
      //   due_day: data?.rule?.due_day,
      //   create_day: data?.rule?.due_day - data?.rule?.previous_days,
      // }))
      // setNumberOfPages(Math.ceil(response.data.total / limit))
      setCompanies([])
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }
  useEffect(() => {
    if (numberOfPages === 1) {
      setPaginationOn(false)
    }
    getCompaniesBillet()
  }, [page])

  const createDay = (day: string) => {
    if (Number(day) <= 0) {
      const daysLastMonth = moment(month).subtract(1, 'month').daysInMonth()
      const newMonthForm = moment(month).subtract(1, 'month').format('YYYY-MM')
      const newDay = daysLastMonth + Number(day)

      return day || Number(day) === 0
        ? moment(`${newMonthForm}-${newDay}`).format('DD/MM/YYYY')
        : '-'
    }

    const monthForm = moment(month).format('YYYY-MM')
    return day ? moment(`${monthForm}-${day}`).format('DD/MM/YYYY') : '-'
  }

  const changeDate = (value: any) => {
    setMonth(value)
    getCompaniesBillet(name, page, value)
  }

  const verifyMonth =
    moment(month).format('MM-YYYY') === moment().format('MM-YYYY')

  // eslint-disable-next-line consistent-return
  const handleVerifyRule = async (id: string, nameCompany: string) => {
    setCompanySelected({
      id,
      name: nameCompany,
    })
    setModalAdd(true)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        // await api.post(`manager/financial/company/billet`, {
        //   company_id: companySelected.id,
        //   amount: values.amount,
        //   due_date: moment(values.due_date).format('YYYY-MM-DD'),
        //   fine: values.fine,
        //   instructions: values.observation,
        //   interest: values.tax,
        // })
        alertSuccess('Boleto criado com sucesso')
        await getCompaniesBillet()
        formik.resetForm()
      } catch (err: any) {
        alertDanger(err?.response?.data?.message || 'Erro ao gerar boleto')
      }
      setLoading(false)
      setModalAdd(false)
    },
  })

  const handleSearch = () => {
    setPaginationOn(false)
    getCompaniesBillet(name, 1)
    setPage(1)
  }

  const handleRemove = async () => {
    try {
      await api.delete(`payments/v1/company/invoice/${removeId}`)
      await getCompaniesBillet('', page)
    } catch (err: any) {
      alertWarning(err?.response?.data?.message || 'Occoreu algum erro!')
    }
    setModalRemove(false)
  }
  const contentModalRemove = () => (
    <Modal
      isOpen={modalRemove}
      toggle={() => {
        setModalRemove(modalRemove)
      }}
    >
      <ModalHeader>
        <h3>Você deseja realmente Cancelar esse boleto dessa empresa?</h3>
      </ModalHeader>
      <ModalBody className="p-4 d-flex justify-content-between">
        <Button
          color="primary"
          onClick={() => {
            handleRemove()
          }}
        >
          Sim
        </Button>
        <Button
          style={{ minWidth: 200 }}
          color="secondary"
          onClick={() => {
            setModalRemove(false)
          }}
        >
          Não
        </Button>
      </ModalBody>
    </Modal>
  )
  const contentModalAdd = () => (
    <Modal
      isOpen={modalAdd}
      toggle={() => {
        setModalAdd(modalAdd)
      }}
    >
      <ModalHeader>
        <h3>{`Gerar um boleto para empresa ${companySelected?.name}`}</h3>
      </ModalHeader>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <ModalBody>
            <Col sm={12} className="d-flex">
              <FormGroup style={{ width: '100%' }}>
                <label className="form-control-label" htmlFor="input-username">
                  Data de vencimento
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: 'DD/MM/YYYY',
                    }}
                    timeFormat={false}
                    onChange={(e: any) => {
                      formik.setValues({
                        ...formik.values,
                        due_date: e,
                      })
                    }}
                    dateFormat="DD/MM/YYYY"
                    isValidDate={(currentDate) => currentDate.isAfter(moment())}
                    value={formik.values.due_date || ''}
                  />
                </InputGroup>
                {formik.errors.due_date && formik.touched.due_date && (
                  <div className="input-feedback">{formik.errors.due_date}</div>
                )}
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Valor do boleto
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Informe o valor do boleto.."
                  name="amount"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.amount || ''}
                  error={formik.errors.amount}
                />
                {formik.errors.amount && formik.touched.amount && (
                  <div className="input-feedback">{formik.errors.amount}</div>
                )}
              </FormGroup>
              <Row className="d-flex">
                <Col sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Juros
                    </label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Valor do Juros"
                      name="tax"
                      type="number"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.tax || ''}
                    />
                  </FormGroup>
                </Col>

                <Col sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Multa
                    </label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Valor da Multa"
                      name="fine"
                      type="number"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.fine || ''}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Observações:
                </label>
                <Input
                  type="textarea"
                  name="observation"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.observation || ''}
                />
              </FormGroup>
            </Col>

            <Col className="d-flex justify-content-end">
              <Button color="primary" disabled={!formik.isValid} type="submit">
                Criar
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  formik.resetForm()
                  setModalAdd(false)
                }}
              >
                Cancelar
              </Button>
            </Col>
          </ModalBody>
        </Form>
      </FormikProvider>
    </Modal>
  )

  return (
    <div>
      <Card className="shadow">
        {contentModalRemove()}
        {contentModalAdd()}
        {loading ? (
          <Loading title="Carregando..." />
        ) : (
          <>
            <CardHeader className="bg-transparent border-0">
              <Row className="align-items-center d-flex justify-content-between">
                <Col sm="auto" className="mb-2">
                  <h3 className="mb-0 text-black">Boletos Empresas</h3>
                </Col>

                <Col sm="auto" className="d-flex justify-content-end">
                  <Col sm="auto">
                    <div className="d-flex align-items-center">
                      <Button
                        className="btn-icon btn-2"
                        color="primary"
                        size="sm"
                        onClick={() => {
                          changeDate(moment(month).subtract(1, 'month'))
                        }}
                      >
                        <i className="fa fa-chevron-left" />
                      </Button>
                      <h4 className="mb-0 text-aling-center">
                        {moment(month).format('MMM YYYY')}
                      </h4>
                      <Button
                        className="btn-icon btn-2 ml-1"
                        color="primary"
                        size="sm"
                        disabled={verifyMonth}
                        onClick={() => {
                          changeDate(moment(month).add(1, 'month'))
                        }}
                      >
                        <i className="fa fa-chevron-right" />
                      </Button>
                    </div>
                  </Col>
                  <Col xs="6">
                    <Input
                      type="text"
                      id="filtrar-name"
                      maxLength={14}
                      value={name}
                      placeholder="Filtrar pelo nome..."
                      onChange={(e) => setName(e.target.value)}
                      onKeyDown={(e) => handleEnterPress(e, handleSearch)}
                    />
                  </Col>
                  <Col xs="auto">
                    <Button
                      color="secondary"
                      onClick={() => {
                        setPaginationOn(false)
                        getCompaniesBillet(name, 1)
                        setPage(1)
                      }}
                    >
                      <i className="fas fa-search" />
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      color="primary"
                      onClick={() => {
                        getCompaniesBillet('', 1)
                        setName('')
                        setPaginationOn(true)
                      }}
                    >
                      <i className="far fa-trash-alt" />
                    </Button>
                  </Col>
                </Col>
              </Row>
            </CardHeader>

            <Row className="mt-0">
              <div className="col">
                <Card className="shadow">
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    hover
                    fluid="true"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">id da empresa</th>
                        <th scope="col">nome</th>
                        <th scope="col">cnpj</th>
                        <th scope="col">dia de criação</th>
                        <th scope="col">dia do vencimento</th>
                        <th scope="col">status do boleto</th>
                        <th scope="col">ações</th>
                      </tr>
                    </thead>

                    <tbody>
                      {companies?.map((company) => (
                        <tr key={company.id}>
                          <th>{company.id}</th>
                          <td>{company.name}</td>
                          <td>{cpfCnpjMask(company.cnpj)}</td>
                          <td>{createDay(company?.create_day)}</td>
                          <td>{createDay(company?.due_day)}</td>
                          <td>{generateStatus(company.status_billet)}</td>
                          <td className="d-flex justify-content-end">
                            <div style={{ width: 30, marginLeft: 5 }}>
                              {(!company.status_billet ||
                                company.status_billet === 'canceled') && (
                                <Button
                                  size="sm"
                                  color="success"
                                  disabled={!verifyMonth}
                                  onClick={() => {
                                    handleVerifyRule(company.id, company.name)
                                  }}
                                >
                                  <i className="fas fa-plus" />
                                </Button>
                              )}
                            </div>
                            <div style={{ width: 30, marginLeft: 5 }}>
                              {(company.status_billet === 'pending' ||
                                company.status_billet === 'expired') && (
                                <Button
                                  size="sm"
                                  color="primary"
                                  onClick={() => {
                                    setRemoveId(company.id_billet)
                                    setModalRemove(true)
                                  }}
                                >
                                  <i className="fas fa-ban" />
                                </Button>
                              )}
                            </div>

                            <div style={{ width: 30, marginLeft: 5 }}>
                              {company.url_billet && (
                                <Button
                                  size="sm"
                                  href={company.url_billet}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="far fa-eye" />
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {paginationOn && (
                    <PaginationComponent
                      numberOfPages={numberOfPages}
                      page={page}
                      setPage={setPage}
                    />
                  )}
                </Card>
              </div>
            </Row>
          </>
        )}
      </Card>
    </div>
  )
}

export default BilletMonth
