/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'

// Components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import { alertWarning, alertSuccess } from 'utils/toast'

import api from 'configs/api'
import Loading from 'components/Loading'
import PaginationComponent from 'components/PaginationComponent'
import ComponentBack from 'components/ComponentBack'
import moment from 'moment'

const StarsExclusion = () => {
  const [loading, setLoading] = useState(false)
  const [datas, setDatas] = useState<any>()
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)
  const [type, setType] = useState('pending')

  const limit = 10

  const getStars = async (initialPage = page) => {
    setLoading(true)
    try {
      const { data } = await api.get(
        `/accounts/ms/v1/user-exclusion?page=${initialPage}&take=${limit}&status=${type}`
      )

      const datasUser = data.users.map((item: any) => ({
        id: item.user_id,
        requestDate: item.request_date,
        name: item.name,
        email: item.email,
        status: item.status === 'pending' ? 'Pendente' : 'Finalizado',
        finished: item.updated_at,
        protocol: item.protocol,
        // has_appointment: item.has_appointment,
      }))
      setDatas(datasUser)
      const totalPages = Math.ceil(data.total / limit)
      if (data?.total === 0 || totalPages === 1) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }
      setNumberOfPages(totalPages)
    } catch (err: any) {
      alertWarning(err?.response?.data?.message || 'Ocorreu algum erro.')
    }
    setLoading(false)
  }
  useEffect(() => {
    if (numberOfPages === 1) {
      setPaginationOn(false)
    }
    getStars()
  }, [page, type])

  const handleSubmit = async (id: string) => {
    setLoading(true)
    try {
      const res = await api.put(`/accounts/ms/v1/user-exclusion/${id}`)
      getStars()
      alertSuccess(res.data.message || 'Processo finalizado com sucesso!')
    } catch (err: any) {
      alertWarning(err?.response?.data?.message || 'Ocorreu algum erro.')
    }
    toggle()
    setLoading(false)
  }
  if (loading) return <Loading title="Carregando solicitações..." />
  return (
    <div>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <>
            <CardHeader className="bg-transparent border-0">
              <Row className=" d-flex justify-content-between">
                <Col sm="auto" className="mb-2">
                  <h3 className="mb-0 text-black">
                    {type === 'pending'
                      ? 'Estrelas com solicitação pendente'
                      : 'Estrelas com solicitação finalizada'}
                  </h3>
                </Col>
                <Col sm="3">
                  <FormGroup className="mb-0">
                    <Input
                      type="select"
                      style={{ paddingRight: 10 }}
                      onChange={(e) => setType(e.target.value)}
                      value={type}
                    >
                      <option value="pending">Pendentes</option>
                      <option value="finished">Finalizados</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </CardHeader>
            <Row className="mt-0">
              <div className="col">
                <Card className="shadow">
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">id-protocolo</th>
                        <th scope="col">id</th>
                        <th scope="col">nome</th>
                        <th scope="col">email</th>
                        <th scope="col">data da solicitação</th>
                        <th scope="col">Tipo de Exclusão</th>
                        {type === 'finished' && (
                          <th scope="col">data da finalização</th>
                        )}
                        <th scope="col">status</th>
                        {type === 'pending' && <th scope="col">ação</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {datas &&
                        datas.map((d: any) => (
                          <tr key={d.id}>
                            <th scope="row">{`#${d?.protocol || ''}`}</th>
                            <th scope="row">{d.id}</th>
                            <th scope="row">{d.name}</th>
                            <th scope="row">{d.email}</th>
                            <th scope="row">
                              {moment(d.requestDate).format('DD/MM/YYYY')}
                            </th>
                            <th scope="row">
                              {d.has_appointment ? 'Parcial' : 'Total'}
                            </th>
                            {type === 'finished' && (
                              <th scope="row">
                                {moment(d.finished).format('DD/MM/YYYY')}
                              </th>
                            )}

                            <th scope="row">{d.status}</th>

                            {type === 'pending' && (
                              <th scope="col">
                                <Button
                                  outline
                                  size="sm"
                                  sm="auto"
                                  color="primary"
                                  onClick={() => {
                                    handleSubmit(d.id)
                                  }}
                                >
                                  Excluir
                                </Button>
                              </th>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {paginationOn && (
                    <PaginationComponent
                      numberOfPages={numberOfPages}
                      page={page}
                      setPage={setPage}
                    />
                  )}
                </Card>
              </div>
            </Row>
          </>
        </Card>
      </Container>
    </div>
  )
}

export default StarsExclusion
