import React, { useState, useEffect } from 'react'

import {
  CardHeader,
  Container,
  CardBody,
  Row,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Table,
} from 'reactstrap'
import ReactDatetime from 'react-datetime'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'

import api from 'configs/api'
import { alertDanger } from 'utils/toast'

import moment from 'moment'

const StelaIA = () => {
  const [loading, setLoading] = useState(false)
  const [AI, setAI] = useState<any>([])

  const [select, setSelect] = useState({ sigla: 'text', name: 'Texto' })

  const [dates, setDates] = useState<any>({
    start: moment().format('YYYY-MM'),
  })
  const type = [
    {
      sigla: 'text',
      name: 'Texto',
    },
    {
      sigla: 'audio',
      name: 'Áudio',
    },
  ]

  const getAI = async () => {
    setLoading(true)
    try {
      const res = await api.get(
        `/ai/v1/chat/reports?month=${dates?.start}&sessionType=${select.sigla}`
      )

      setAI(res.data)
    } catch (err: any) {
      alertDanger('Erro ao carregar os dados da Stela IA.')
    }
    setLoading(false)
  }

  const handleSelectChange = (e: any) => {
    const { selectedIndex } = e.target
    setSelect({
      sigla: e.target.value,
      name: e.target.options[selectedIndex].text,
    })
  }

  useEffect(() => {
    getAI()
  }, [dates, select.sigla])
  const csta = AI?.metrics?.csat || null
  return (
    <div>
      {loading && <Loading title="Carregando..." />}
      <HeaderNeutro />
      <Container fluid className="nav-wrapper ">
        <CardBody className="p-0">
          <Container>
            <Row className="d-flex">
              <Col className="mb-4 d-flex align-items-stretch justify-between">
                <Card className="shadow h-100 w-100">
                  <Row className="align-items-center mt-4 ml-2 mr-2">
                    <Col xs={6} md={4}>
                      <FormGroup>
                        <h5 className="mb-2 text-black">Selecione um mês</h5>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: 'Selecione a data',
                            }}
                            value={dates.start}
                            dateFormat="YYYY-MM"
                            timeFormat={false}
                            closeOnSelect
                            onChange={(e: any) => {
                              setDates({
                                ...dates,
                                start: moment(e).format('YYYY-MM'),
                              })
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={4}>
                      <FormGroup>
                        <h5 className="mb-2 text-black">Tipo do chat</h5>
                        <Input
                          type="select"
                          style={{ paddingRight: 10 }}
                          placeholder="Selecione..."
                          onChange={handleSelectChange}
                          value={select.sigla}
                        >
                          <option value="">Selecione...</option>
                          {type?.map((item: any) => (
                            <option key={item.sigla} value={item.sigla}>
                              {item.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col sm="12">
                        <h3 className="mb-0">
                          {select?.sigla ? select?.name : 'Texto'}
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table
                    className="align-items-center table-flush d-flex"
                    responsive
                  >
                    <Col>
                      <table style={{ width: '100%' }}>
                        <tr>
                          <th>Total de chats</th>
                          <td>{AI?.metrics?.totalChats || 0}</td>
                        </tr>
                        <tr>
                          <th>Chats abertos</th>
                          <td>{AI?.metrics?.openChats || 0}</td>
                        </tr>
                        <tr>
                          <th>Chats fechados</th>
                          <td>{AI?.metrics?.closedChats || 0}</td>
                        </tr>
                        <tr>
                          <th>CSAT</th>
                          <td>
                            {AI?.metrics?.csat
                              ? AI?.metrics?.csat?.toFixed(2)
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <th>Total de menssagens</th>
                          <td>{AI?.metrics?.totalMessages || 0}</td>
                        </tr>
                        <tr>
                          <th>Usuários únicos</th>
                          <td>{AI?.metrics?.uniqueUsersCount || 0}</td>
                        </tr>
                      </table>
                    </Col>
                  </Table>
                </Card>
              </Col>
            </Row>
            <Card className="border-0 shadow">
              <CardHeader className="border-0">
                <Row className=" align-items-center d-flex">
                  <Col sm="auto">
                    <h3 className="mb-0">Comentários de avaliações</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table
                className="table-flush d-flex"
                style={{ maxHeight: '400px', overflowY: 'auto' }}
                responsive
              >
                <Col>
                  <table
                    style={{
                      width: '100%',
                      tableLayout: 'fixed',
                      borderCollapse: 'collapse',
                    }}
                  >
                    {AI?.descriptions?.map((ai: any) => (
                      <tr>
                        <th
                          style={{
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            textAlign: 'left',
                            padding: '10px',
                          }}
                        >
                          {ai}
                        </th>
                      </tr>
                    ))}
                  </table>
                </Col>
              </Table>
            </Card>
          </Container>
        </CardBody>
      </Container>
    </div>
  )
}

export default StelaIA
