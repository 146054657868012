import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Card,
  CardHeader,
  Col,
  CardBody,
  Button,
  FormGroup,
  Input,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import ComponentBack from 'components/ComponentBack'
import Loading from 'components/Loading'
import { alertDanger, alertSuccess } from 'utils/toast'

import { useLocation } from 'react-router-dom'

import RichTextEditor from 'react-rte'
import api from 'configs/api'

const applications = [{ key: 'app', label: 'app' }]

const Privacy = () => {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue())
  const [editable, setEditable] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState('')
  const [loading, setLoading] = useState(false)

  const { state }: any = useLocation()

  useEffect(() => {
    setValue(
      RichTextEditor.createValueFromString(state.privacy.policies, 'html')
    )
    setSelectedApplication(state.privacy.application)
  }, [])

  const handleUpdate = async () => {
    setLoading(true)
    try {
      await api.put(`/backoffice/ms/v1/policies/${state?.privacy?.id}`, {
        policies: value.toString('html'),
        type: selectedApplication,
        lang: state?.privacy.lang,
      })
      alertSuccess('Política atualizado com sucesso')
      setEditable(false)
    } catch (error) {
      alertDanger('Erro ao atualizar Política')
      console.log(error)
    }
    setLoading(false)
  }

  if (loading) return <Loading title="Aguarde..." />
  return (
    <div>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="d-flex justify-content-between">
                <Col sm="auto" className="mb-2">
                  <h3 className="mb-0 text-black">Políticas de privacidade</h3>
                </Col>
                <Col sm="3" className="mb-2">
                  <Row className="d-flex flex-cow">
                    <Col sm="6">
                      <FormGroup>
                        <Input
                          type="select"
                          id="application"
                          name="application"
                          value={selectedApplication}
                          onChange={(e: any) =>
                            setSelectedApplication(e.target.value)
                          }
                        >
                          <option value="" selected>
                            Aplicação
                          </option>
                          {applications.map((e) => (
                            <option
                              key={e.key}
                              value={e.label}
                              label={e.label}
                            />
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col>
                      <Button
                        sm="auto"
                        color="primary"
                        onClick={() => {
                          if (editable) {
                            handleUpdate()
                          } else {
                            setEditable(true)
                          }
                        }}
                        disabled={selectedApplication === ''}
                      >
                        {editable ? 'Salvar' : 'Editar'}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <RichTextEditor
                      value={value}
                      onChange={setValue}
                      rootStyle={{ width: '100%', border: '1px solid black' }}
                      disabled={!editable}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Privacy
