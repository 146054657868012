/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
  Table,
  CardTitle,
} from 'reactstrap'
import ReactSpeedometer from 'react-d3-speedometer'

import Loading from 'components/Loading'

import moment from 'moment'
import api from 'configs/api'

const Assessment = ({ select }: any) => {
  const [month, setMonth] = useState(new Date())
  const [countCsat, setCountCsat] = useState(0)
  const [countNps, setCountNps] = useState(0)
  const [comments, setComments] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const dateFormated = (value: Date) => {
    const dayInit = moment(value).format('01/MM/YYYY')
    const lastDay = moment.utc(value).daysInMonth()
    const dayEnd = moment(value).format(`${lastDay}/MM/YYYY`)
    return `start=${dayInit}&end=${dayEnd}`
  }

  const getNpsAndCsat = async (value = month) => {
    setLoading(true)
    try {
      const formattedDate = dateFormated(value)
      const response = await api.get(
        `/rating/ms/v1/countries?${formattedDate}&country=${select?.sigla}`
      )

      setComments(response.data.comments)

      const csatValue = response?.data?.csat || 0
      const npsValue = response?.data?.nps || 0

      setCountCsat(Number(csatValue))
      setCountNps(Number(npsValue))
    } catch (e) {
      console.error('Erro ao buscar CSAT e NPS:', e)
    } finally {
      setLoading(false)
    }
  }

  const changeDate = (value: any) => {
    setMonth(value)
    getNpsAndCsat(value)
  }

  const verifyMonth =
    moment(month).format('MM-YYYY') === moment().format('MM-YYYY')

  useEffect(() => {
    getNpsAndCsat()
  }, [select])

  return (
    <>
      {loading && <Loading title="Aguarde..." />}
      <CardHeader className="mb-4">
        <Row className="justify-content-between">
          <h2>{`Dados de avaliações - ${select.name}`}</h2>
          <div className="d-flex align-items-center">
            <Button
              className="btn-icon btn-2"
              color="primary"
              size="sm"
              onClick={() => {
                changeDate(moment(month).subtract(1, 'month'))
              }}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <h4 style={{ whiteSpace: 'nowrap' }} className="mb-0">
              {moment(month).format('MMM YYYY')}
            </h4>
            <Button
              className="btn-icon btn-2 ml-1"
              color="primary"
              size="sm"
              disabled={verifyMonth}
              onClick={() => {
                changeDate(moment(month).add(1, 'month'))
              }}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Row>
      </CardHeader>
      <Row className="d-flex align-items-center justify-content-between">
        <Col className="col-sm">
          <Card className="card-stats mb-4 mb-lg-3 shadow">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    CSAT
                  </CardTitle>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-chart-line" />
                  </div>
                </Col>
              </Row>
              <CardBody
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ReactSpeedometer
                  value={countCsat}
                  maxValue={100}
                  needleColor="steelblue"
                  needleTransitionDuration={1500}
                  height={200}
                />
              </CardBody>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-sm">
          <Card className="card-stats mb-4 mb-lg-3 shadow">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    NPS
                  </CardTitle>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-chart-line" />
                  </div>
                </Col>
              </Row>
              <CardBody
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ReactSpeedometer
                  value={countNps}
                  maxValue={100}
                  needleColor="steelblue"
                  needleTransitionDuration={1500}
                  height={200}
                />
              </CardBody>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Card className="shadow">
        <CardHeader className="bg-transparent border-0">
          <Row className="d-flex align-items-center justify-content-between">
            <Col sm="auto" className="mb-2">
              <h3 className="mb-0 text-black">Comentários</h3>
            </Col>
          </Row>
        </CardHeader>
        <Row className="mt-0">
          <div className="col">
            <Card className="shadow">
              <Table
                className="align-items-center table-flush"
                responsive
                hover
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Comentário</th>
                  </tr>
                </thead>
                <tbody>
                  {comments?.length > 0 ? (
                    comments.map((comment: any) => (
                      <tr key={comment.id}>
                        <td>{comment.user_name}</td>
                        <td>{comment.user_cellphone}</td>
                        <td>{comment.comment}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>Sem comentários</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Card>
    </>
  )
}

export default Assessment
