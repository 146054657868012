import React, { useState } from 'react'
import Loading from 'components/Loading'

import { Button, Card, CardImg, Col, Row } from 'reactstrap'
import profileImg from 'assets/img/brand/avatar.jpg'
import { useHistory } from 'react-router-dom'

import EditIcon from '@mui/icons-material/Edit'
import { alertSuccess, alertDanger } from 'utils/toast'
import { useDropzone } from 'react-dropzone'
import api from 'configs/api'

import { PhotoProfile } from '../styles'

const CardDoctor = ({
  doctorCard,
  schedules,
  schedulingsCanceled,
  schedulingsFinished,
  doctorUser,
}: any) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const onClickSchedule = () => {
    history.push(`/admin/agenda/${doctorCard.name}`, { doctorCard })
  }

  const onClickQueries = () => {
    history.push(`/admin/consultas/${doctorCard.name}`, {
      doctorCard,
    })
  }

  /* eslint-disable */
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      const maxSize = 4 * 2048 * 2048
      const acceptFiles = [
        'image/jpeg',
        'image/pjpeg',
        'image/png',
        'image/gif',
      ]

      if (acceptedFiles[0].size > maxSize) {
        return alertDanger('A imagem não pode ser maior que 16Mb!')
      }

      if (!acceptFiles.includes(acceptedFiles[0].type)) {
        return alertDanger(
          'Tipo do arquivo inválido, só são aceitos imagens do tipo JPG, JPEG, PNG, GIF.'
        )
      }
      setLoading(true)

      const doctorId = doctorCard?.id
      const data = new FormData()
      data.append('file', acceptedFiles[0])

      try {
        await api.post(`/doctors/v1/user/${doctorId}/upload`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        setLoading(false)
        doctorUser()
        alertSuccess('Foto alterada com sucesso!')
      } catch (err) {
        setLoading(false)
        alertDanger('Erro ao alterar a foto, tente novamente!')
      }
    },
  })

  return (
    <>
      {loading && <Loading title="Carregando..." />}

      <Card className="shadow p-3">
        <Row>
          <Col className="d-flex justify-content-center">
            <PhotoProfile>
              <div className="content-photo">
                <div {...getRootProps()} className="photo-content-wrapper">
                  <CardImg
                    alt=""
                    src={doctorCard?.photo || `${profileImg}`}
                    style={{
                      borderRadius: '50%',
                      marginBottom: '1rem',
                      width: '180px',
                      height: '180px',
                      marginTop: '-90px',
                      backgroundSize: 'auto',
                    }}
                  />
                  <input {...getInputProps()} />
                  <div className="edit-icon">
                    <EditIcon />
                  </div>
                </div>
              </div>
            </PhotoProfile>
          </Col>
        </Row>

        <Row className="d-flex  justify-content-center p-2 mb-1">
          <Col
            md="4"
            className="d-flex flex-column align-items-center text-center  p-2"
            style={{ fontSize: 14 }}
          >
            <h4 className="d-flex justify-content-center">{schedules || 0}</h4>
            <h6 className=" heading-small text-muted p-0  ">
              Horários Abertos
            </h6>
          </Col>
          <Col
            md="4"
            className="d-flex flex-column align-center  p-2 text-center"
            style={{ fontSize: 14 }}
          >
            <h4 className="d-flex justify-content-center">
              {schedulingsFinished || 0}
            </h4>
            <h6 className="heading-small text-muted  p-0  ">
              Consultas Finalizadas
            </h6>
          </Col>
          <Col
            md="4"
            className="d-flex flex-column align-center  p-2 text-center"
            style={{ fontSize: 14 }}
          >
            <h4 className="d-flex justify-content-center ">
              {schedulingsCanceled || 0}
            </h4>
            <h6 className="heading-small text-muted   p-0 ">
              Consultas Canceladas
            </h6>
          </Col>
        </Row>
        <div
          style={{
            borderBottom: '1px solid #e6e1f1',
            marginBottom: '2rem',
          }}
        >
          {' '}
        </div>
        <Row>
          <Col className="d-flex mb-3">
            <Button
              type="button"
              style={{
                fontSize: 12,
                backgroundColor: '#fff',
                color: '#FF3F72',
              }}
              onClick={() => onClickSchedule()}
            >
              Agenda do Profissional
            </Button>
            <Button
              type="button"
              style={{
                fontSize: 12,
                backgroundColor: '#fff',
                color: '#FF3F72',
              }}
              onClick={() => onClickQueries()}
            >
              {' '}
              Próximas Consultas
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  )
}
export default CardDoctor
