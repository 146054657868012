import styled from 'styled-components'

export const CalendarView = styled.div``

export const PhotoProfile = styled.div`
  margin-bottom: 2rem;
  margin-top: 50px;


  .content-photo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px;

    .photo-content-wrapper {
      .edit-icon {
        width: 3rem;
        height: 3rem;
        background: #fff;
        box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        margin-top: -50px;
        margin-left: 120px;

        svg {
          color: #FF3F72
        }
      }

    @media screen and (min-width: 961px) {
      justify-content: center;
    }
  }

  @media screen and (min-width: 961px) {
    margin-bottom: 0;
    margin-top: -50px;
  }
`
