import React, { useEffect } from 'react'
// @ts-ignore
import Chart from 'chart.js'

// Components
import { Card, Container, Row, Col } from 'reactstrap'

import { chartOptions, parseOptions } from 'variables/charts'

import useSpecialty from 'hooks/useSpecialty'
import { useAuth } from 'context/authentication/auth.context'

import ChartAppointments from './Components/ChartAppointments'
import ChartCsat from './Components/ChartCsat'
import ChartNps from './Components/ChartNps'

import * as S from './styles'

const Index = () => {
  const { getSpecialties } = useSpecialty()
  const { verifyUser } = useAuth()

  // @ts-ignore
  if (window.Chart) {
    parseOptions(Chart, chartOptions())
  }

  useEffect(() => {
    getSpecialties()
    verifyUser()
  }, [])

  return (
    <div className=" d-flex flex-direction-column" style={{ width: '100%' }}>
      <S.BgContainer>
        <Container className="mt-5" fluid>
          <Row>
            <Col xl="5">
              <Card className="shadow mt-3">
                <ChartAppointments />
              </Card>
              <Card className="shadow mt-3 mb-3">
                <ChartCsat />
              </Card>
            </Col>
            <Col xl="7">
              <Card className="shadow mt-3 mb-3">
                <ChartNps />
              </Card>
            </Col>
          </Row>
        </Container>
      </S.BgContainer>
    </div>
  )
}
export default Index
