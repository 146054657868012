/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'

// Components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Input,
  Button,
  UncontrolledTooltip,
} from 'reactstrap'
import { cpfCnpjMask } from 'js-essentials-functions'
import { useHistory } from 'react-router-dom'

import useFetch from 'hooks/useFetch'
import { handleEnterPress } from 'utils/enterPress'

import PaginationComponent from 'components/PaginationComponent'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'

const Stars = () => {
  const [paginationOn, setPaginationOn] = useState(true)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState('')
  const [search, setSearch] = useState('')
  const [tooltipOpen, setTooltipOpen] = useState<string | null>(null)

  const history = useHistory()
  const limit = 10

  const { data, isLoading } = useFetch(
    `accounts/ms/v1/users?page=${page}&limit=${limit}&filter=${search}`,
    true,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const nPages = Math.ceil(data?.total / limit)

  const onBuscar = (id: string) => {
    history.push(`/admin/estrela/${id}`, {
      id,
    })
  }

  const copyToClipboard = (id: string) => {
    const inputTest = document.createElement('input')
    inputTest.value = id
    document.body.appendChild(inputTest)
    inputTest.select()
    document.execCommand('copy')
    document.body.removeChild(inputTest)
    setTooltipOpen(id)
    setTimeout(() => setTooltipOpen(null), 1000)
  }

  const addFilter = () => {
    setSearch(filter)
  }

  const handleSearch = () => {
    addFilter()
    setPaginationOn(true)
  }

  return (
    <div>
      {isLoading && <Loading title="Carregando Estrelas..." />}
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <div className="col">
          <Row className="mt-3 align-items-end d-flex justify-content-end">
            <Col sm="4">
              <Input
                type="text"
                id="filter"
                value={filter}
                placeholder="Filtrar pelo nome ou email ou CPF..."
                onChange={(e) => setFilter(e.target.value)}
                onKeyDown={(e) => handleEnterPress(e, handleSearch)}
              />
            </Col>
            <Col sm="auto">
              <Button
                sm="auto"
                color="secondary"
                onClick={() => {
                  addFilter()
                  setPaginationOn(true)
                }}
              >
                <i className="fas fa-search" />
              </Button>
            </Col>
            <Col sm="auto">
              <Button
                sm="auto"
                color="primary"
                onClick={() => {
                  setPage(1)
                  setFilter('')
                  setSearch('')
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="mt-3" fluid>
        <div className="col">
          <Card className="shadow">
            <>
              <CardHeader className="bg-transparent border-0">
                <Row className=" d-flex justify-content-between">
                  <Col sm="auto" className="mb-2">
                    <h3 className="mb-0 text-black">Estrelas Ativas</h3>
                  </Col>
                  <Col sm="auto" className="mb-2">
                    <Button
                      outline
                      color="primary"
                      onClick={() => {
                        history.push(`/admin/exclusao/estrelas`)
                      }}
                      style={{ position: 'relative' }}
                    >
                      {` Solicitações de exclusão `}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Row className="mt-0">
                <div className="col">
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                      hover
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">id</th>
                          <th scope="col">nome</th>
                          {/* <th scope="col">nome social</th> */}
                          <th scope="col">email</th>
                          <th scope="col">documento</th>
                          <th scope="col">status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data?.users?.map((d: any) => (
                            <tr key={d.id}>
                              <td
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <span id={`tooltip${d.id}`}>
                                  {d.id.substring(0, 8)}
                                  ...
                                </span>
                                <i
                                  className="far fa-copy"
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '0.5rem',
                                  }}
                                  onClick={() => copyToClipboard(d.id)}
                                  id={`copyIcon${d.id}`}
                                />
                                <UncontrolledTooltip
                                  trigger="hover"
                                  delay={0}
                                  placement="bottom"
                                  target={`tooltip${d.id}`}
                                >
                                  {d.id}
                                </UncontrolledTooltip>
                                <UncontrolledTooltip
                                  trigger="hover click"
                                  delay={0}
                                  placement="bottom"
                                  target={`copyIcon${d.id}`}
                                  isOpen={tooltipOpen === d.id}
                                >
                                  {tooltipOpen === d.id && 'Copiado!'}
                                </UncontrolledTooltip>
                              </td>
                              <td
                                onClick={() => {
                                  onBuscar(d.id)
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {d.name}
                              </td>
                              {/* <td
                                onClick={() => {
                                  onBuscar(d.id)
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {d.social_name}
                              </td> */}
                              <td
                                onClick={() => {
                                  onBuscar(d.id)
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {d.email}
                              </td>
                              <td
                                onClick={() => {
                                  onBuscar(d.id)
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {cpfCnpjMask(d.document || '')}
                              </td>
                              <td
                                onClick={() => {
                                  onBuscar(d.id)
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {d.status}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {paginationOn && (
                      <PaginationComponent
                        numberOfPages={nPages}
                        page={page}
                        setPage={setPage}
                      />
                    )}
                  </Card>
                </div>
              </Row>
            </>
          </Card>
        </div>
      </Container>
    </div>
  )
}

export default Stars
