import React from 'react'

import { alertSuccess, alertWarning } from 'utils/toast'
import api from 'configs/api'

import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

const StatusDoctorModal = ({
  modal,
  setModal,
  statusActive,
  setLoading,
  setStatusActive,
  doctorData,
}: any) => {
  const toggle = () => setModal(!modal)

  const handleStatus = async () => {
    setLoading(true)
    try {
      const res = await api.patch(`doctors/ms/v1/user/${doctorData?.id}/status`)
      alertSuccess(res.data.message)
      setStatusActive(!statusActive)
    } catch (err: any) {
      alertWarning(err.response.data.message)
    }
    setModal(false)
    setLoading(false)
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3>
            {statusActive
              ? 'Deseja inativar o médico?'
              : 'Deseja ativar o médico?'}
          </h3>
        </ModalHeader>
        <ModalBody className="d-flex">
          <Button
            onClick={() => {
              handleStatus()
            }}
          >
            Sim
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setModal(false)
            }}
          >
            Não
          </Button>
        </ModalBody>
      </Modal>
    </div>
  )
}
export default StatusDoctorModal
