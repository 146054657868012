import Loading from 'components/Loading'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import api from 'configs/api'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import ComponentBack from 'components/ComponentBack'
import InputPassword from 'components/InputPassword'
import { dateMaskUS } from 'utils/convertDate'

import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  Container,
} from 'reactstrap'

import { alertSuccess, alertWarning } from 'utils/toast'

import * as yup from 'yup'
import {
  cpfCnpjMask,
  dateMask,
  telephoneMask,
  telephoneUnmask,
} from 'js-essentials-functions'
import { useLocation } from 'react-router-dom'

const schemaRegister = yup.object().shape({
  name: yup.string().required('O campo é obrigatório.'),
  email: yup
    .string()
    .email('E-mail inválido.')
    .required('O campo é obrigatório.'),
  cellphone: yup.string().required('O campo é obrigatório.'),
  gender: yup.string().required('O campo é obrigatório.'),
  password: yup.string(),
})

const genders = [
  {
    label: 'Masculino',
    key: 'Masculino',
  },
  {
    label: 'Feminino',
    key: 'Feminino',
  },
]
const Nurse = () => {
  const [editable, setEditable] = useState(false)
  const [loading, setLoading] = useState(false)
  const { state }: any = useLocation()
  const [nameUser, setNameUser] = useState(state.params.name)

  const initialValues = useMemo(
    () => ({
      name: '',
      document: '',
      birthDay: '',
      email: '',
      cellphone: '',
      gender: '',
      crm: '',
      password: '',
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      const userData = {
        name: values.name,
        email: values.email,
        cellphone: telephoneUnmask(values.cellphone),
        birthdate: values.birthDay,
        gender: values.gender,
        password: values.password,
      }

      try {
        await api.put(`/nursing/users/${state.params.id}`, userData)
        alertSuccess('Dados atualizados com sucesso')
        setNameUser(values.name)
        setEditable(false)
      } catch (err: any) {
        alertWarning(
          err?.response?.data?.message ||
            'Ocorreu um erro ao atualizar os dados'
        )
        console.log(err)
      }
      setLoading(false)
    },
  })

  const getNursse = async () => {
    setLoading(true)
    try {
      const response = await api.get(`manager/nursings/${state?.params?.id}`)
      const { item } = response.data

      formik.setValues({
        ...initialValues,
        name: item?.name || '',
        document: item.profiles?.document || '',
        birthDay: dateMaskUS(item.profiles?.birthdate) || '',
        email: item?.email || '',
        cellphone: item.profiles?.cellphone || '',
        gender: item.profiles?.gender || '',
        crm:
          `${item.profiles?.professional_document_number} ${item.profiles?.professional_document_uf}` ||
          '',
      })
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (state.params.id) {
      getNursse()
    }
  }, [state])

  return (
    <>
      {loading && <Loading title="Carregando..." />}
      <HeaderNeutro />
      <ComponentBack />
      <Container md="9" className="mt-3" fluid>
        <Row>
          <Col>
            <Form onSubmit={formik.handleSubmit}>
              <Card className="shadow">
                <CardHeader>
                  <h3>{nameUser}</h3>
                </CardHeader>
                <CardBody style={{ backgroundColor: '#F8FeF9' }}>
                  <h6 className="heading-small text-muted ">Informações</h6>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label for="name" style={{ fontSize: 14 }}>
                          Nome
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="name"
                          name="name"
                          type="text"
                          disabled={!editable}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik?.values?.name || ''}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <div className="input-feedback">
                            {formik.errors.name}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label for="name" style={{ fontSize: 14 }}>
                          Email
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="email"
                          name="email"
                          type="text"
                          disabled={!editable}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values?.email || ''}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="input-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label for="document" style={{ fontSize: 14 }}>
                          CPF
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="document"
                          name="document"
                          type="text"
                          maxLength={14}
                          disabled
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={cpfCnpjMask(formik.values?.document || '')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label for="birthDay" style={{ fontSize: 14 }}>
                          Data de nascimento
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="birthDay"
                          name="birthDay"
                          type="text"
                          maxLength={10}
                          disabled={!editable}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={dateMask(formik.values?.birthDay || '')}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <label htmlFor="gender">Sexo biológico</label>

                        <Input
                          type="select"
                          id="gender"
                          name="gender"
                          disabled={!editable}
                          value={formik.values?.gender || ''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option label="Sexo biológico" value="" />
                          {genders.map((e) => (
                            <option
                              key={e.key}
                              value={e.label}
                              label={e.label}
                            />
                          ))}
                        </Input>
                        {formik.errors.gender && formik.touched.gender && (
                          <div className="input-feedback">
                            {formik.errors.gender}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label for="crm" style={{ fontSize: 14 }}>
                          Documento
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-crm"
                          name="crm"
                          type="text"
                          disabled
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values?.crm || ''}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label for="name" style={{ fontSize: 14 }}>
                          Celular
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-cellphone"
                          type="text"
                          name="cellphone"
                          maxLength={15}
                          disabled={!editable}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={telephoneMask(formik.values.cellphone || '')}
                        />
                        {formik.errors.cellphone &&
                          formik.touched.cellphone && (
                            <div className="input-feedback">
                              {formik.errors.cellphone}
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="password"
                        >
                          Nova Senha
                        </label>
                        <InputPassword
                          className="form-control-alternative"
                          id="password"
                          placeholder=""
                          name="password"
                          type="password"
                          disabled={!editable}
                          autoComplete="new-password"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.password}
                        />
                        {formik.errors.password && formik.touched.password && (
                          <div className="input-feedback">
                            {formik.errors.password}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-end">
                    <Col lg="3">
                      {editable && (
                        <Button type="submit" color="secondary" title="Salvar">
                          <i className="" style={{ fontSize: 16 }} />
                          Salvar
                        </Button>
                      )}
                      {!editable && (
                        <Button
                          type="button"
                          color="secondary"
                          onClick={() => setEditable(true)}
                          title="Editar"
                        >
                          <i className="" style={{ fontSize: 16 }} />
                          Editar
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Nurse
