import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Media,
  Row,
  Button,
  Input,
  Table,
  FormGroup,
  ModalBody,
  Modal,
  ModalHeader,
} from 'reactstrap'
import Avatar from 'assets/img/brand/avatar.jpg'
import moment from 'moment'
import api from 'configs/api'
import PaginationComponent from 'components/PaginationComponent'
import PermissionComponent from 'components/PermissionComponent'
import Loading from 'components/Loading'

import { alertWarning, alertSuccess } from 'utils/toast'

moment.locale('pt-br')

const Historic = ({ userId }: any) => {
  const [appointments, setAppointments] = useState<any>()
  const [filter, setFilter] = useState('all')
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [schedulingId, setSchedulingId] = useState('')
  const [statusValue, setStatusVelue] = useState('pending')

  // Pagination
  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10

  const toggle = () => setModal(!modal)

  const getColor = (status: string) => {
    if (status === 'pending') return 'text-orange'
    if (status === 'started') return 'text-blue'
    if (status === 'finished') return 'text-green'
    if (status === 'canceled') return 'text-red'
    return 'text-black'
  }
  const getStatus = (status: string) => {
    if (status === 'pending') return 'Atendimento Pendente'
    if (status === 'started') return 'Atendimento Iniciado'
    if (status === 'finished') return 'Atendimento Finalizado'
    if (status === 'canceled') return 'Atendimento Cancelado'
    return 'Atendimento Pendente'
  }

  const statusItems = ['pending', 'started', 'finished']

  const getAppointments = async () => {
    setLoading(true)
    try {
      const res = await api.get(
        `schedulings/ms/v1/user/${userId}/all?page=${page}&limit=${limit}&status=${filter}`
      )
      setAppointments(res?.data?.appointments)

      // paginação
      const nPages = Math.ceil(res?.data?.total / limit)
      setNumberOfPages(nPages)
      if (nPages === 1 || nPages === 0) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAppointments()
  }, [page, filter, userId])

  const changeStatusScheduling = async () => {
    setLoading(true)
    try {
      await api.post(`/schedulings/ms/v1/${schedulingId}/status`, {
        status: statusValue,
      })
      await getAppointments()
      alertSuccess('Consulta alterada com sucesso')
      setSchedulingId('')
      setModal(false)
    } catch (error) {
      alertWarning('Ocorreu um erro ao alterar status do agendamento')
      console.log(error)
    }
    setLoading(false)
  }

  const modalStatus = () => (
    <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: 400 }}>
      <ModalHeader toggle={toggle}>
        <h3>Altere o status do agendamento</h3>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Input
                type="select"
                id="status"
                name="status"
                value={statusValue}
                onChange={(e) => setStatusVelue(e.target.value)}
              >
                {statusItems.map((item) => (
                  <option value={item} label={getStatus(item)} />
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <Button
              type="submit"
              color="primary"
              onClick={() => {
                toggle()
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              color="secondary"
              onClick={() => {
                changeStatusScheduling()
              }}
            >
              Alterar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  return (
    <>
      {modalStatus()}
      {loading && <Loading title="Buscando consultas..." />}
      <Card className="shadow mt-3">
        <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
          <Col sm="auto">
            <h2 className="mb-0 text-black">Histórico de Consultas</h2>
          </Col>
          <Col sm="3">
            <Input
              type="select"
              style={{ paddingRight: 10 }}
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            >
              <option value="all">Todas</option>
              <option value="pending">Pendentes</option>
              <option value="canceled">Canceladas</option>
              <option value="finished">Finalizadas</option>
            </Input>
          </Col>
        </CardHeader>
      </Card>
      {appointments?.length > 0 && (
        <Card>
          <CardBody>
            <Row className="align-items-center">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{}</th>
                    <th scope="col">status</th>
                    <PermissionComponent role="SUPERADMIN,ADMIN">
                      <th scope="col">ação</th>
                    </PermissionComponent>
                    <th scope="col">médico</th>
                    <th scope="col">especialidade</th>
                    <th scope="col">data da consulta</th>
                    <th scope="col">tipo de consulta</th>
                    <th scope="col">descrição do cancelamento</th>
                    <th scope="col">data do cancelamento</th>
                    <th scope="col">prescrição</th>
                  </tr>
                </thead>
                <tbody>
                  {appointments.map((d: any) => (
                    <tr key={d.id}>
                      <td>
                        <Media className="align-items-center avatar rounded-circle">
                          <img
                            style={{
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              maxWidth: 50,
                              maxHeight: 50,
                            }}
                            alt="Avatar que representa o usuário"
                            src={d.img_doctor || Avatar}
                          />
                        </Media>
                      </td>
                      <td>
                        <h5 className={getColor(d?.status)}>
                          {getStatus(d?.status)}
                        </h5>
                      </td>
                      <PermissionComponent role="SUPERADMIN,ADMIN">
                        <td>
                          <Button
                            size="sm"
                            color="secondary"
                            onClick={() => {
                              setSchedulingId(d.id)
                              setModal(true)
                            }}
                          >
                            Alterar status
                          </Button>
                        </td>
                      </PermissionComponent>
                      <td>{`Dr. ${d?.doctor_name}`}</td>
                      <td>{d?.specialty_name}</td>
                      <td>
                        {moment
                          .utc(d?.from_date_time)
                          .format('DD/MM/YYYY [às] HH:mm')}
                      </td>
                      <td>
                        {d?.plan_associate_id ? 'Plano' : 'Consulta Avulsa'}
                      </td>

                      <td>{d?.cancel_description}</td>
                      <td>
                        {d?.cancel_at
                          ? moment(d?.cancel_at).format('DD/MM/YYYY [às] HH:mm')
                          : ''}
                      </td>
                      <td>
                        {d?.prescription && (
                          <a
                            target="_blank"
                            href={`${d.prescription?.patient_url}${d.prescription?.validation_code}`}
                            rel="noreferrer"
                          >
                            <Button
                              type="submit"
                              style={{ width: 200 }}
                              outline
                              color="primary"
                            >
                              Ver Prescrição
                            </Button>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </CardBody>
        </Card>
      )}

      {paginationOn && (
        <PaginationComponent
          numberOfPages={numberOfPages}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default Historic
