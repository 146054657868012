import useSWR, { useSWRConfig } from 'swr'
import api from 'configs/api'

export { useSWRConfig }

interface Props {
  revalidateIfStal?: boolean
  revalidateOnMount?: boolean
  revalidateOnFocus?: boolean
  revalidateOnReconnect?: boolean
  refreshInterval?: number
  dedupingInterval?: number
  headers?: any
}

export default (url: string, condition = true, options?: Props) => {
  const fetcher = async (urlFetcher: string) => {
    const response: any = await api.get(urlFetcher, options)

    return response.data
  }

  const { data, error, isLoading, isValidating } = useSWR(
    condition ? url : null,
    fetcher,
    {
      ...options,
    }
  )
  return { data, error, isLoading, isValidating }
}
