/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  Progress,
  CardBody,
  CardTitle,
} from 'reactstrap'

import moment from 'moment'
import Loading from 'components/Loading'
import useFetch from 'hooks/useFetch'

const Queries = ({ select }: any) => {
  const [month, setMonth] = useState(new Date())

  const dateFormated = (value: Date) => {
    const dayInit = moment(value).format('01/MM/YYYY')
    const lastDay = moment.utc(value).daysInMonth()
    const dayEnd = moment(value).format(`${lastDay}/MM/YYYY`)
    return `start=${dayInit}&end=${dayEnd}`
  }

  const verifyMonth =
    moment(month).format('MM-YYYY') === moment().format('MM-YYYY')
  const getPorcent = (value: any, total: any) =>
    value !== '0' && total !== 0
      ? ((Number(value) / Number(total)) * 100).toFixed(1)
      : 0

  const { data, isLoading } = useFetch(
    `schedulings/ms/v1/reports/status?${dateFormated(month)}&country=${
      select.sigla
    }`,
    true,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const changeDate = (value: any) => {
    setMonth(value)
  }

  return (
    <>
      {isLoading && <Loading title="Aguarde..." />}
      <CardHeader className="mb-4">
        <Row className="justify-content-between">
          <h2>{`Dados de consultas - ${select.name}`}</h2>
          <div className="d-flex align-items-center">
            <Button
              className="btn-icon btn-2"
              color="primary"
              size="sm"
              onClick={() => {
                changeDate(moment(month).subtract(1, 'month'))
              }}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <h4 style={{ whiteSpace: 'nowrap' }} className="mb-0">
              {moment(month).format('MMM YYYY')}
            </h4>
            <Button
              className="btn-icon btn-2 ml-1"
              color="primary"
              size="sm"
              disabled={verifyMonth}
              onClick={() => {
                changeDate(moment(month).add(1, 'month'))
              }}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Row>
      </CardHeader>
      <Row>
        <Col className="col-sm">
          <Card className="card-stats mb-4 mb-lg-3 shadow">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Consultas futuras
                  </CardTitle>
                  <span className="h1 font-weight-bold mb-0 text-black">
                    {data?.appointments?.pending}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-calendar-plus" />
                  </div>
                </Col>
              </Row>
              <div className="mt-3">
                <span className="mr-2 text-gray">
                  {`${getPorcent(
                    data?.appointments?.pending,
                    data?.appointments?.total
                  )}%`}
                </span>
                <div>
                  <Progress
                    max="100"
                    value={getPorcent(
                      data?.appointments?.pending,
                      data?.appointments?.total
                    )}
                    barClassName="bg-gradient-danger"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-sm">
          <Card className="card-stats mb-4 mb-lg-3 shadow">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Consultas finalizados
                  </CardTitle>
                  <span className="h1 font-weight-bold mb-0 text-black">
                    {data?.appointments?.finished}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-calendar-check" />
                  </div>
                </Col>
              </Row>
              <div className="mt-3">
                <span className="mr-2 text-gray">
                  {`${getPorcent(
                    data?.appointments?.finished,
                    data?.appointments?.total
                  )}%`}
                </span>
                <div>
                  <Progress
                    max="100"
                    value={getPorcent(
                      data?.appointments?.finished,
                      data?.appointments?.total
                    )}
                    barClassName="bg-gradient-success"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="col-sm">
          <Card className="card-stats mb-4 mb-lg-3 shadow">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Consultas cancelados
                  </CardTitle>
                  <span className="h1 font-weight-bold mb-0 text-black">
                    {data?.appointments?.canceled}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-calendar-minus" />
                  </div>
                </Col>
              </Row>
              <div className="mt-3">
                <span className="mr-2 text-gray">
                  {`${getPorcent(
                    data?.appointments?.canceled,
                    data?.appointments?.total
                  )}%`}
                </span>
                <div>
                  <Progress
                    max="100"
                    value={getPorcent(
                      data?.appointments?.canceled,
                      data?.appointments?.total
                    )}
                    barClassName="bg-gradient-orange"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-sm">
          <Card className="card-stats mb-4 mb-lg-3 shadow">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    No-show
                  </CardTitle>
                  <span className="h1 font-weight-bold mb-0 text-black">
                    {data?.appointments?.no_show}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                    <i className="fas fa-calendar-times" />
                  </div>
                </Col>
              </Row>
              <div className="mt-3">
                <span className="mr-2 text-gray">
                  {`${getPorcent(
                    data?.appointments?.no_show,
                    data?.appointments?.total
                  )}%`}
                </span>
                <div>
                  <Progress
                    max="100"
                    value={getPorcent(
                      data?.appointments?.no_show,
                      data?.appointments?.total
                    )}
                    barClassName="bg-gradient-danger"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default Queries
