import React, { useState, useEffect } from 'react'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  Table,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'

import Loading from 'components/Loading'
import api from 'configs/api'
import { alertDanger, alertSuccess } from 'utils/toast'
import { handleEnterPress } from 'utils/enterPress'

import PaginationComponent from 'components/PaginationComponent'
import ModalContent from './components/ModalContent'

const Content = () => {
  const [modal, setModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [contentId, setContentId] = useState<string>('')
  const [getContent, setGetContent] = useState<any>(null)
  const [modalDel, setModalDel] = useState<boolean>(false)
  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const [statusContent, setStatusContent] = useState<any>(null)
  const [datas, setDatas] = useState<any>(null)

  // paginação
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [paginationOn, setPaginationOn] = useState(true)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState('')
  const limit = 10

  const getContents = async (numberPage = page, filtername = '') => {
    setLoading(true)
    try {
      const { data } = await api.get(
        `/backoffice/ms/v1/content?page=${numberPage}&take=${limit}&filter=${filtername}`
      )
      if (Math.ceil(data.total / limit) === 1) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }
      setDatas(data.items)
      setNumberOfPages(Math.ceil(data.total / limit))
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const action = () => {
    getContents(1, '')
  }

  const toggle = () => {
    setModalDel(!modalDel)
    setContentId('')
  }

  const desableContent = async () => {
    setLoading(true)
    try {
      await api.patch(`/backoffice/ms/v1/content/${contentId}`, {
        status: statusContent === 'active' ? 'inactive' : 'active',
      })
      setModalStatus(!modalStatus)
      action()

      alertSuccess(
        statusContent === 'active'
          ? 'Conteúdo inativado com sucesso'
          : 'Conteúdo ativado com sucesso'
      )
    } catch (error) {
      console.log(error)
      alertDanger(
        statusContent === 'active'
          ? 'Não foi possivel inativar o conteúdo'
          : 'Não foi possivel ativar o conteúdo'
      )
    }
    setLoading(false)
  }

  const deleteContent = async () => {
    setLoading(true)
    try {
      await api.delete(`/backoffice/ms/v1/content/${contentId}`)
      toggle()
      action()
      alertSuccess('Conteúdo excluído com sucesso')
    } catch (error) {
      console.log(error)
      alertDanger('Não foi possivel excluir o conteúdo')
    }
    setLoading(false)
  }

  const contentModalStatus = () => (
    <Modal
      isOpen={modalStatus}
      toggle={() => {
        setModalStatus(!modalStatus)
      }}
    >
      <ModalHeader>
        <h2>
          {statusContent === 'active'
            ? 'Você deseja realmente inativar esse conteúdo?'
            : 'Você deseja ativar esse conteúdo?'}
        </h2>
      </ModalHeader>
      <ModalBody>
        <Row className="d-flex justify-content-end">
          <Col sm="6">
            <Button color="primary" onClick={() => desableContent()}>
              Sim
            </Button>
          </Col>
          <Col sm="6">
            <Button
              color="secondary"
              onClick={() => {
                setModalStatus(false)
              }}
            >
              Não
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  const modalDelete = () => (
    <Modal isOpen={modalDel} toggle={toggle}>
      <ModalHeader>
        <h2>Você deseja realmente remover esse conteúdo?</h2>
      </ModalHeader>
      <ModalBody>
        <Row className="d-flex justify-content-end">
          <Col sm="6">
            <Button color="primary" onClick={() => deleteContent()}>
              Sim
            </Button>
          </Col>
          <Col sm="6">
            <Button color="secondary" onClick={toggle}>
              Não
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  const handleSearch = () => {
    setPaginationOn(false)
    getContents(1, filter)
    setPage(1)
  }

  useEffect(() => {
    if (numberOfPages === 1) {
      setPaginationOn(false)
    }
    getContents()
  }, [page])

  return (
    <div>
      {loading && <Loading title="Carregando..." />}
      <HeaderNeutro />
      <Container fluid>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="justify-content-start">
            <Button
              color="secondary"
              onClick={() => {
                setModal(true)
              }}
            >
              <i className="fas fa-plus" />
            </Button>
          </Col>
          <Col sm="8" className="d-flex justify-content-end">
            <Col xs="4">
              <Input
                type="text"
                id="filter"
                placeholder="Filtrar pelo título"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                onKeyDown={(e) => handleEnterPress(e, handleSearch)}
              />
            </Col>
            <Col xs="auto">
              <Button
                color="secondary"
                onClick={() => {
                  setPaginationOn(false)
                  getContents(1, filter)
                  setPage(1)
                }}
              >
                <i className="fas fa-search" />
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                onClick={() => {
                  if (page === 1) {
                    getContents(1, '')
                  }
                  setPage(1)
                  setFilter('')
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </Col>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <CardHeader className="bg-transparent border-0">
            <Row className="align-items-center">
              <Col sm="9" className="mb-2">
                <h3 className="mb-0 text-black">Conteúdo</h3>
              </Col>
            </Row>
          </CardHeader>
          <Row className="mt-0">
            <div className="col">
              <Card className="shadow">
                <Table
                  className="align-items-center table-flush"
                  responsive
                  hover
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Título</th>
                      <th scope="col">Tipo</th>
                      <th scope="col">Autor</th>
                      <th scope="col">Ações</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datas?.map((item: any) => (
                      <tr key={item.id} style={{ cursor: 'pointer' }}>
                        <td>{item.title}</td>
                        <td>{item.category}</td>
                        <td>{item.author}</td>
                        <td>
                          {item.status === 'active' ? 'Ativo' : 'Inativo'}
                        </td>
                        <td className="d-flex align-items-center">
                          <div style={{ width: 30 }}>
                            <Button
                              size="sm"
                              onClick={() => {
                                setModal(true)
                                // eslint-disable-next-line
                                setGetContent(item)
                              }}
                            >
                              <i className="fa fa-pen" />
                            </Button>
                          </div>
                          {item?.status === 'active' ? (
                            <div style={{ width: 30, marginLeft: 10 }}>
                              <Button
                                size="sm"
                                onClick={() => {
                                  setStatusContent(item.status)
                                  setContentId(item.id)
                                  setModalStatus(true)
                                }}
                              >
                                <i className="fa fa-ban" />
                              </Button>
                            </div>
                          ) : (
                            <div style={{ width: 30, marginLeft: 10 }}>
                              <Button
                                size="sm"
                                onClick={() => {
                                  setStatusContent(item.status)
                                  setContentId(item.id)
                                  setModalStatus(true)
                                }}
                              >
                                <i className="fas fa-check" />
                              </Button>
                            </div>
                          )}
                          <div style={{ width: 30, marginLeft: 10 }}>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => {
                                // eslint-disable-next-line
                                setContentId(item.id)
                                setModalDel(true)
                              }}
                            >
                              <i className="far fa-trash-alt" />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
          <Col className="align-items-end">
            {paginationOn && (
              <PaginationComponent
                numberOfPages={numberOfPages}
                page={page}
                setPage={setPage}
              />
            )}
          </Col>
        </Card>
      </Container>
      <ModalContent
        modal={modal}
        setModal={setModal}
        action={action}
        getContent={getContent}
        setGetContent={setGetContent}
        setLoading={setLoading}
      />
      {modalDelete()}
      {contentModalStatus()}
    </div>
  )
}

export default Content
