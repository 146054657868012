import React, { useEffect, useState, useCallback } from 'react'

import {
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  CardBody,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import moment from 'moment'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import ptLocale from '@fullcalendar/core/locales/pt-br'

import Loading from 'components/Loading'
import useSpecialty from 'hooks/useSpecialty'
import api from 'configs/api'

import './app.css'

const TabSettings = () => {
  const { getSpecialties, filterSpecialtiesActive } = useSpecialty()

  const [month, setMonth] = useState(moment(new Date()).format('YYYY/MM'))
  const [specialtyId, setSpecialtyId] = useState('')
  const [doctors, setDoctors] = useState<any[]>([])
  const [doctorsPlantao, setDoctorsPlantao] = useState<any[]>([])
  const [specialty, setSpecialty] = useState('')
  const [days, setDays] = useState([])
  const [daysOccupied, setDaysOccupied] = useState([])

  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setDoctors([])
    setModal(!modal)
  }

  const getAllSpecialties = async () => {
    setLoading(true)
    await getSpecialties()
    setLoading(false)
  }
  useEffect(() => {
    getAllSpecialties()
  }, [])

  const removeAccents = (text: any) =>
    text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  const filterSpecialties = () => {
    if (specialty) {
      return filterSpecialtiesActive().filter((item) =>
        removeAccents(item.name)
          .toLocaleLowerCase()
          .includes(removeAccents(specialty).toLocaleLowerCase())
      )
    }
    return filterSpecialtiesActive()
  }

  const getAvailableDays = useCallback(
    async (id = specialtyId, mes: string) => {
      const newMes = mes.replace('/', '-')
      setLoading(true)
      try {
        const { data } = await api.get(
          `/schedule/v1/schedules/days/specialty/${id}?month=${newMes}`
        )
        setDays(
          data.days.map((dat: any) => ({
            start: dat,
            allDay: true,
            title: 'Hor. disp.',
            id: dat,
            display: 'background',
            backgroundColor: '#ffbcce',
          }))
        )
        setDaysOccupied(
          data.days_occupied.map((dat: any) => ({
            start: dat,
            allDay: true,
            title: 'Hor. ocup.',
            id: dat,
            display: 'background',
            backgroundColor: '#FF4242',
          }))
        )
      } catch (err: any) {
        console.log(err)
      }
      setLoading(false)
    },
    [specialtyId, month]
  )

  const selectDay = async (day: string) => {
    setDoctors([])
    setLoading(true)
    try {
      const { data } = await api.get(
        `/schedule/v1/schedules/doctors/specialty/${specialtyId}?day=${day}`
      )
      setDoctors(data.doctors)
      setDoctorsPlantao(data.doctorsPlantao)
      setModal(true)
    } catch (err: any) {
      console.log(err)
    }
    setLoading(false)
  }

  const ModalDoctors = () => (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>Profissionais da Saúde</h3>
      </ModalHeader>
      <ModalBody className="pt-0">
        {doctors && doctors.length > 0 && <h3>Padrão</h3>}

        {doctors &&
          doctors.length > 0 &&
          doctors.map((item, index) => {
            const noPlanSlots = item.slots.filter((it: any) => it?.plans === '')
            return (
              <div key={item.id + String(index)}>
                <h4>{`${item.name} (Horários)`}</h4>
                {noPlanSlots.length > 0 && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginBottom: 15,
                      }}
                    >
                      {noPlanSlots.map((it: any) => (
                        <Button
                          size="sm"
                          outline={!it.appointment_id}
                          color="secondary"
                          key={it.slot_id}
                          disabled
                          style={{
                            width: 60,
                            marginBottom: 5,
                            marginRight: 2.5,
                            marginLeft: 2.5,
                          }}
                        >
                          {it.time}
                        </Button>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )
          })}
        {doctorsPlantao && doctorsPlantao.length > 0 && <h3>Plantão</h3>}
        {doctorsPlantao &&
          doctorsPlantao.length > 0 &&
          doctorsPlantao.map((item, index) => {
            const noPlanSlots = item.slots.filter((it: any) => it?.plans !== '')
            return (
              <div key={item.id + String(index)}>
                <h4>{`${item.name} (Horários)`}</h4>
                {noPlanSlots.length > 0 && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginBottom: 15,
                      }}
                    >
                      {noPlanSlots.map((it: any) => (
                        <Button
                          size="sm"
                          outline={!it.appointment_id}
                          color="secondary"
                          key={it.slot_id}
                          disabled
                          style={{
                            width: 60,
                            marginBottom: 5,
                            marginRight: 2.5,
                            marginLeft: 2.5,
                          }}
                        >
                          {it.time}
                        </Button>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )
          })}
      </ModalBody>
    </Modal>
  )
  return (
    <Card className="shadow">
      {ModalDoctors()}
      {loading && <Loading title="Aguarde..." />}
      <CardHeader className="bg-transparent border-0">
        <Col sm="9">
          <h3 className="mb-0 text-black">Especialidades e Agendas</h3>
        </Col>
      </CardHeader>
      <CardBody>
        <Row className="mt-0">
          <Col sm="3" style={{ height: 700 }}>
            <div style={{ paddingTop: 20 }}>
              <FormGroup>
                <Input
                  placeholder="Buscar especialidade..."
                  onChange={(e) => setSpecialty(e.target.value)}
                  value={specialty}
                  type="text"
                />
              </FormGroup>
              <div
                style={{
                  maxHeight: 600,
                  paddingRight: 15,
                  overflowY: 'auto',
                }}
              >
                {filterSpecialties()?.map((item: any) => (
                  <Row key={item.id}>
                    <Col style={{ marginBottom: 5 }}>
                      <Button
                        size="sm"
                        outline
                        color="primary"
                        key={item.id}
                        onClick={() => {
                          setSpecialtyId(item.id)
                          getAvailableDays(item.id, month)
                        }}
                        active={item.id === specialtyId}
                      >
                        {item.name}
                      </Button>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          </Col>
          <div className="col">
            <Card className="shadow">
              <FullCalendar
                datesSet={(e: any) => {
                  setMonth(moment(e?.startStr).format('YYYY/MM'))
                  if (specialtyId) {
                    getAvailableDays(
                      specialtyId,
                      moment(e?.startStr).format('YYYY/MM')
                    )
                  }
                }}
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                locale={ptLocale}
                dayHeaderFormat={{
                  weekday: 'short',
                }}
                height={700}
                timeZone="America/Sao_Paulo"
                firstDay={1}
                titleFormat={{ year: 'numeric', month: 'short' }}
                events={[...days, ...daysOccupied]}
                eventClick={(info: any) => selectDay(info.event.id)}
                showNonCurrentDates={false}
              />
            </Card>
          </div>
        </Row>
      </CardBody>
    </Card>
  )
}
export default TabSettings
