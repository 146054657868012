import Dashboard from 'containers/Home/Dashboard'
import International from 'containers/Home/Components/Internacional'
import Profile from 'containers/Account/Profile'
import Login from 'containers/Account/Login'

import Doctors from 'containers/Doctors'
import NewDoctor from 'containers/Doctors/NewDoctor'
import Doctor from 'containers/Doctors/Doctor'
import ScheduleDoctor from 'containers/Doctors/ScheduleDoctor'
import SchedulingsDoctor from 'containers/Doctors/SchedulingsDoctor'
import Specialties from 'containers/Specialties'
import Nurses from 'containers/Nurses'
import NewNurse from 'containers/Nurses/NewNurse'
import Nurse from 'containers/Nurses/Nurse'
import Faq from 'containers/Faq'
import Administer from 'containers/Administer'
import Company from 'containers/Companies/Company'
import Companies from 'containers/Companies'
import ImportUser from 'containers/Companies/ImportUser'
import NewCompany from 'containers/Companies/NewCompany'
import Starzen from 'containers/Starzen'
import Content from 'containers/AreaContent'
import StelaIA from 'containers/StelaIA'
import FoodMenu from 'containers/FoodMenu'

import Terms from 'containers/Terms'
import Term from 'containers/Terms/Term'
import PrivacyPolicy from 'containers/Policy'
import Policy from 'containers/Policy/Privacy'

import Plans from 'containers/Plans'
import NewPlans from 'containers/Plans/NewPlans'

import Financial from 'containers/Financial'

import Stars from 'containers/Stars'
import StarsExclusion from 'containers/Stars/StarsExclusion'
import Star from 'containers/Stars/Star'
import Notifications from 'containers/Notifications'
import CreateNotification from 'containers/Notifications/CreateNotification'

const routes = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/internacional',
    name: 'Internacional',
    icon: 'ni ni-world',
    component: International,
    layout: '/admin',
  },
  {
    path: '/stela',
    name: 'Stela IA',
    icon: 'fas fa-robot',
    component: StelaIA,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN',
  },
  {
    path: '/menu',
    name: 'Menu Alimentar',
    icon: 'fas fa-utensils',
    component: FoodMenu,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN',
  },
  {
    path: '/medicos',
    name: 'Time de Saúde',
    icon: 'ni ni-sound-wave',
    component: Doctors,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN,MARKETING',
  },
  {
    path: '/medico/novo',
    name: 'Novo Profissional da Saúde',
    icon: '',
    component: NewDoctor,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
  },
  {
    path: '/profissional/:id',
    name: 'Detalhes do profissional da saúde',
    icon: '',
    component: Doctor,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
  },
  {
    path: '/agenda/:id',
    name: 'Agenda do profissional da saúde',
    icon: '',
    component: ScheduleDoctor,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
    exact: true,
  },
  {
    path: '/consultas/:id',
    name: 'Próximas Consultas',
    icon: '',
    component: SchedulingsDoctor,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
    exact: true,
  },
  {
    path: '/enfermeiras',
    name: 'Enfermeiras',
    icon: 'fas fa-user-nurse',
    component: Nurses,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
  },
  {
    path: '/enfermeira/nova',
    name: 'Enfermeiras',
    icon: '',
    component: NewNurse,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
  },
  {
    path: '/enfermeira/:id',
    name: 'Enfermeiras',
    icon: '',
    component: Nurse,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
  },
  {
    path: '/empresas',
    name: 'Empresas',
    icon: 'ni ni-building',
    component: Companies,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
  },
  {
    path: '/empresa/novo',
    name: 'Nova Empresa',
    icon: '',
    component: NewCompany,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
  },
  {
    path: '/empresa/:id',
    name: 'Visualizar Empresa',
    icon: '',
    component: Company,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
  },
  {
    path: '/importar/:id',
    name: 'Importação colaboradores',
    icon: '',
    component: ImportUser,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN',
  },
  {
    path: '/estrelas',
    name: 'Estrelas',
    icon: 'fas fa-users',
    component: Stars,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN,MARKETING',
  },
  {
    path: '/exclusao/estrelas',
    name: 'Solicitações de Exclusão de Estrela',
    icon: '',
    component: StarsExclusion,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN,MARKETING',
  },
  {
    path: '/estrela/:id',
    name: 'Detalhes da Estrela',
    icon: '',
    component: Star,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN,MARKETING',
  },
  {
    path: '/financeiro',
    name: 'Financeiro',
    // icon: '',
    icon: 'fas fa-coins',
    component: Financial,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN',
  },
  {
    path: '/starzen',
    name: 'Starzen',
    mui: 'meditation',
    component: Starzen,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN',
    icon: '',
  },
  {
    path: '/content',
    name: 'Conteúdo',
    icon: 'fas fa-newspaper',
    component: Content,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN',
  },
  {
    path: '/notificacoes',
    name: 'Notificações',
    icon: 'far fa-bell',
    component: Notifications,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN,MARKETING',
  },
  {
    path: '/notificacao/nova',
    name: 'Notificações',
    icon: '',
    component: CreateNotification,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN,MARKETING',
  },
  {
    path: '/especialidades',
    name: 'Especialidades',
    icon: 'ni ni-book-bookmark',
    component: Specialties,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN,SUPPORT',
  },
  {
    path: '/planos',
    name: 'Planos',
    icon: 'far fa-star',
    component: Plans,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN',
  },
  {
    path: '/plano/novo',
    name: 'Planos',
    icon: '',
    component: NewPlans,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN',
  },
  {
    path: '/termos',
    name: 'Termos',
    icon: 'fa fa-file-contract',
    component: Terms,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN,SUPPORT',
  },

  {
    path: '/termo/:id',
    name: 'Termo',
    icon: '',
    component: Term,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN,SUPPORT',
  },
  {
    path: '/politicas',
    name: 'Políticas',
    icon: 'fa fa-file-contract',
    component: PrivacyPolicy,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN,SUPPORT',
  },
  {
    path: '/politica/:id',
    name: 'Política',
    icon: '',
    component: Policy,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN,SUPPORT',
  },
  {
    path: '/faqs',
    name: 'FAQ',
    icon: 'fa fa-question',
    component: Faq,
    layout: '/admin',
  },
  {
    path: '/admin',
    name: 'Administração',
    icon: '',
    component: Administer,
    layout: '/admin',
    role: 'ADMIN,SUPERADMIN',
  },
  {
    path: '/user-profile',
    name: 'Perfil',
    icon: '',
    component: Profile,
    layout: '/admin',
    role: 'ADMIN,SUPPORT,SUPERADMIN,MARKETING',
  },

  {
    path: '/login',
    name: 'Login',
    icon: '',
    component: Login,
    layout: '/auth',
  },
]
export default routes
