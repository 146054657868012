import api from 'configs/api'
import { dateMaskUS } from 'js-essentials-functions'
import { SpecialtyProps } from 'context/doctors/doctors.types'
import moment from 'moment'

export const getAllDoctors = async (
  page: number,
  limit: number,
  document: string,
  specialty: string
) => {
  try {
    const { data } = await api.get(
      `/doctors/ms/v1/user?page=${page}&limit=${limit}&filter=${document}&specialty_id=${specialty}`
    )

    const datas = data?.users?.map((item: any) => ({
      id: item.id,
      name: item.name,
      document: item.document,
      email: item.email,
      status: item.status,
    }))
    return {
      status: true,
      doctors: datas,
      total: data.total,
    }
  } catch (err: any) {
    return {
      status: false,
      message: err.response.data.message,
    }
  }
}

export interface DoctorData {
  name: string
  email: string
  password: string
  document: string
  cellphone: string
  gender: string
  birthDay: string
  professionalDocumentType: string
  professionalDocumentUf: string
  professionalDocumentNumber: string
  specialties: {
    id: string
    status: number
  }[]
  bio: string
  countries: string[]
  tags: string[]
  groups: string[]
}

export const createDoctor = async (data: DoctorData, userPhoto?: File[]) => {
  try {
    const response = await api.post('/doctors/ms/v1/user', {
      email: data.email,
      name: data.name,
      password: data.password,
      document: data.document,
      cellphone: data.cellphone,
      gender: data.gender,
      birthdate: data.birthDay,
      professional_document_type: data.professionalDocumentType,
      professional_document_uf: data.professionalDocumentUf,
      professional_document_number: data.professionalDocumentNumber,
      specialties: data.specialties,
      bio: data.bio,
      countries: data.countries,
      tags: data.tags,
      groups: data.groups,
    })

    const doctorId = response?.data?.doctor?.id

    if (doctorId && userPhoto?.length) {
      const formData = new FormData()
      formData.append('file', userPhoto[0])

      try {
        await api.post(`/doctors/v1/user/${doctorId}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      } catch (err) {
        return {
          status: false,
          msg: 'Erro ao enviar a foto do médico.',
        }
      }
    }

    return {
      status: true,
      data: response.data,
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.response?.data?.message || 'Erro ao criar médico',
    }
  }
}

export const createSpecialty = async ({
  name,
  status,
  attendanceTime,
}: SpecialtyProps) => {
  try {
    await api.post('doctors/v1/specialties', {
      name,
      status,
      attendance_time: Number(attendanceTime),
    })
    return {
      status: true,
    }
  } catch (err: any) {
    return {
      status: true,
      msg: err.message,
    }
  }
}
export const updateSpecialty = async ({
  uuid,
  name,
  status,
  attendanceTime,
}: SpecialtyProps) => {
  try {
    await api.put(`manager/specialty/${uuid}`, {
      name,
      status,
      attendance_time: Number(attendanceTime),
    })
    return {
      status: true,
    }
  } catch (err: any) {
    return {
      status: true,
      msg: err.message,
    }
  }
}

export const getSchedule = async (uuid: string) => {
  try {
    const response = await api.get(`/schedule/v1/schedules/doctor/${uuid}`, {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    })

    return {
      status: true,
      schedule: response.data.schedules,
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.message,
    }
  }
}

export const deleteSchedule = async (uuid: string) => {
  try {
    await api.delete(`/schedule/v1/schedules/${uuid}`)

    return {
      status: true,
      msg: 'Agenda excluida com sucesso',
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.response.data.message,
    }
  }
}
interface ScheduleProps {
  userUuid: string
  areaId: string
  start: string
  end: string
  plan: string
  countries: string[]
  specialtyName: string
  slug: string
}

export const validateTime = (slug: string) => {
  if (slug) {
    switch (slug) {
      case 'clinico-geral':
        return '12'
      case 'nutricionista':
        return '30'
      case 'psicologo':
        return '30'
      case 'psiquiatra':
        return '30'
      default:
        return '15'
    }
  }
  return '15'
}

export const createSchedule = async (body: ScheduleProps) => {
  try {
    const day = moment(body.start).format('YYYY-MM-DD')
    const startTime = moment(body.start).format('HH:mm')
    const endTime = moment(body.end).format('HH:mm')
    const groups = body.plan ? { groups: [body.plan] } : {}
    const time = validateTime(body.slug)

    const { data } = await api.post(`/schedule/v1/schedules`, {
      specialty_id: body.areaId,
      day,
      user_id: body.userUuid,
      start_time: startTime,
      end_time: endTime,
      countries: body.countries,
      specialty_time: time,
      specialty_name: body.specialtyName,
      recurrence: false,
      ...groups,
    })

    return {
      status: true,
      msg: 'Agenda criada com sucesso',
      schedule: data?.schedule,
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.response.data.message,
    }
  }
}

export const getDoctorData = async (uuid: string) => {
  try {
    const response = await api.get(`/manager/doctors/${uuid}`)
    const { item } = response?.data

    return {
      status: true,
      item,
    }
  } catch (err: any) {
    return {
      status: true,
      msg: err.message,
    }
  }
}

export const getDoctorSchedulings = async (uuid: string, days: any) => {
  try {
    const response = await api.get(
      `/schedulings/ms/v1/doctor/${uuid}/all?start=${days.start}&end=${days.end}`
    )
    return {
      status: true,
      item: response?.data,
    }
  } catch (err: any) {
    return {
      status: true,
      msg: err.message,
    }
  }
}

export interface userUpdatePrps {
  id: string
  userData: {
    name: string
    email: string
    cellphone: string
    birthdate: string
    gender: string | null
    countries: string[]
    groups: string[]
    tags: string[]
    specialties: string[]
  }
}
export const updateDoctor = async ({ id, userData }: userUpdatePrps) => {
  try {
    const response = await api.put(`/doctors/ms/v1/user/${id}`, {
      name: userData?.name,
      email: userData?.email,
      cellphone: userData?.cellphone,
      birthdate: dateMaskUS(userData?.birthdate),
      gender: userData?.gender,
      countries: userData?.countries,
      groups: userData?.groups,
      tags: userData?.tags,
      specialties: userData?.specialties,
    })

    return {
      status: true,
      message: response?.data?.message || 'Médico atualizado com sucesso.',
    }
  } catch (err: any) {
    return {
      status: false,

      message: err.response.data.message,
    }
  }
}
interface UpdateBioParams {
  id: string
  bio: string
  lang: string
}

export const updateBio = async ({ id, bio, lang }: UpdateBioParams) => {
  try {
    const response = await api.put(`doctors/ms/v1/user/${id}/bios`, {
      lang,
      bio,
    })
    return {
      status: true,
      message: response.data.message || 'Bio do médico atualizado com sucesso.',
    }
  } catch (err: any) {
    const message =
      err.response?.data?.message || 'Erro ao atualizar a bio do médico.'
    return {
      status: false,
      message,
    }
  }
}

export const updateDoctorBio = async ({ id, bio, lang }: any) => {
  try {
    const response = await api.put(`doctors/ms/v1/user/${id}/bios`, {
      lang,
      bio,
    })

    return {
      status: true,
      message: response.data.message || 'Bio do médico atualizado com sucesso.',
    }
  } catch (err: any) {
    return {
      status: false,

      message: err.response.data.message,
    }
  }
}

export const changeScheduling = async (uuid: string, data: any) => {
  try {
    await api.put(`/schedulings/ms/v1/${uuid}/change-date`, data)

    return {
      status: true,
      msg: 'Agendamento alterado com sucesso',
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.message,
    }
  }
}

export const deleteScheduling = async (uuid: string, adminId: string) => {
  try {
    await api.post(`/schedulings/ms/v1/${uuid}/cancel/support`, {
      cancel_description: 'Cancelado pelo suporte',
      user_id: adminId,
    })

    return {
      status: true,
      msg: 'Agendamento excluido com sucesso',
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.message,
    }
  }
}

export const getDaysAVailable = async (
  uuid: string,
  date: string,
  areaId: string,
  time: string
) => {
  try {
    const response = await api.get(
      `/schedulings/v3/verify-time?specialty_id=${areaId}&doctor_id=${uuid}&scheduling_date=${date}&time=${time}`
    )
    return {
      status: true,
      data: response.data.times,
    }
  } catch (err: any) {
    console.log('err', err)
    return {
      status: false,
      msg: err.message,
    }
  }
}
