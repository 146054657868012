import React, { useState } from 'react'
import classnames from 'classnames'

import {
  Container,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Assessment from './Tabs/Assessment'
import Metrics from './Tabs/Metrics'
import Queries from './Tabs/Queries'

const International = () => {
  const [tabs, setTabs] = useState(1)
  const [select, setSelect] = useState({ sigla: 'CA', name: 'Canadá' })
  const countries = [
    {
      sigla: 'CA',
      name: 'Canadá',
    },
    {
      sigla: 'US',
      name: 'Estados Unidos',
    },
  ]

  const handleSelectChange = (e: any) => {
    const { selectedIndex } = e.target
    setSelect({
      sigla: e.target.value,
      name: e.target.options[selectedIndex].text,
    })
  }

  return (
    <div>
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="justify-content-start">
            <Nav
              className="flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={tabs === 1}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: tabs === 1,
                  })}
                  onClick={() => setTabs(1)}
                  href="#Configurações"
                  role="tab"
                >
                  <i className="fas fa-chart-line mr-2" />
                  Dados gerais
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 2}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: tabs === 2,
                  })}
                  onClick={() => setTabs(2)}
                  href="#Configurações"
                  role="tab"
                >
                  <i className="fas fa-thumbs-up mr-2" />
                  Dados de avaliações
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 3}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: tabs === 3,
                  })}
                  onClick={() => setTabs(3)}
                  href="#Configurações"
                  role="tab"
                >
                  <i className="fas fa-stethoscope mr-2" />
                  Dados de consultas
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col sm="auto" className="d-flex justify-content-end">
            <FormGroup className="mb-0">
              <Label className="form-control-label" htmlFor="input-country">
                País
              </Label>
              <Input
                type="select"
                style={{ paddingRight: 10 }}
                placeholder="Selecione..."
                onChange={handleSelectChange}
                value={select.sigla}
              >
                <option value="">Selecione...</option>
                {countries?.map((item: any) => (
                  <option key={item.sigla} value={item.sigla}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <CardBody className="p-4">
            <TabContent activeTab={`tabs${tabs}`}>
              <TabPane tabId="tabs1">
                {tabs === 1 && <Metrics select={select} />}
              </TabPane>
              <TabPane tabId="tabs2">
                {tabs === 2 && <Assessment select={select} />}
              </TabPane>
              <TabPane tabId="tabs3">
                {tabs === 3 && <Queries select={select} />}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default International
