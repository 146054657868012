import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Card,
  CardHeader,
  Col,
  CardBody,
  Button,
  FormGroup,
  Input,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import ComponentBack from 'components/ComponentBack'
import Loading from 'components/Loading'
import { alertDanger, alertSuccess } from 'utils/toast'
import { useLocation } from 'react-router-dom'
import RichTextEditor from 'react-rte'
import api from 'configs/api'

const applications = [{ key: 'app', label: 'app' }]

const Term = () => {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue())
  const [editable, setEditable] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState('')
  const [loading, setLoading] = useState(false)

  const location = useLocation<{ term?: { id: string } }>()
  const newTerm = !location?.state?.term?.id

  const getTerm = async () => {
    setLoading(true)
    try {
      if (!location?.state?.term) {
        throw new Error('Termo não encontrado no estado.')
      }

      const response = await api.get(
        `/backoffice/ms/v1/term/${location.state.term.id}`
      )

      setSelectedApplication(response.data.application)
      setValue(
        RichTextEditor.createValueFromString(response.data.terms, 'html')
      )
    } catch (err) {
      console.log('Erro ao buscar termo:', err)
      alertDanger('Erro ao carregar termo')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!newTerm) {
      getTerm()
    } else {
      setEditable(true)
    }
  }, [newTerm])

  const handleCreate = async () => {
    setLoading(true)
    try {
      await api.post('/backoffice/ms/v1/term', {
        terms: value.toString('html'),
        type: selectedApplication,
        lang: 'en',
      })
      alertSuccess('Termo cadastrado com sucesso')
    } catch (error: any) {
      alertDanger(error?.response?.data?.message || 'Erro ao cadastrar termo')
    }
    setLoading(false)
  }

  const handleUpdate = async () => {
    setLoading(true)
    try {
      await api.put(`/backoffice/ms/v1/term/${location?.state?.term?.id}`, {
        terms: value.toString('html'),
        type: selectedApplication,
        lang: 'en',
      })
      alertSuccess('Termo atualizado com sucesso')
      setEditable(false)
    } catch (error) {
      alertDanger('Erro ao atualizar termo')
      console.log(error)
    }
    setLoading(false)
  }

  if (loading) return <Loading title="Aguarde..." />

  return (
    <div>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="d-flex justify-content-between">
                <Col sm="auto" className="mb-2">
                  <h3 className="mb-0 text-black">Termo de Uso </h3>
                </Col>
                <Col sm="3" className="mb-2">
                  <Row className="d-flex flex-row">
                    <Col sm="6">
                      <FormGroup>
                        <Input
                          type="select"
                          id="application"
                          name="application"
                          value={selectedApplication}
                          disabled={!newTerm}
                          onChange={(e) =>
                            setSelectedApplication(e.target.value)
                          }
                        >
                          <option value="">Aplicação</option>
                          {applications.map((app) => (
                            <option key={app.key} value={app.key}>
                              {app.label}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    {newTerm ? (
                      <Col>
                        <Button
                          sm="auto"
                          color="primary"
                          onClick={handleCreate}
                          disabled={selectedApplication === ''}
                        >
                          Criar
                        </Button>
                      </Col>
                    ) : (
                      <Col>
                        <Button
                          sm="auto"
                          color="primary"
                          onClick={() => {
                            if (editable) {
                              handleUpdate()
                            } else {
                              setEditable(true)
                            }
                          }}
                          disabled={selectedApplication === ''}
                        >
                          {editable ? 'Salvar' : 'Editar'}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <RichTextEditor
                      value={value}
                      onChange={setValue}
                      rootStyle={{ width: '100%', border: '1px solid black' }}
                      disabled={!editable}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Term
