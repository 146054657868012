import React, { useState, useEffect } from 'react'

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Input,
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'

import { handleEnterPress } from 'utils/enterPress'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import PaginationComponent from 'components/PaginationComponent'
import Loading from 'components/Loading'
import api from 'configs/api'

const Plans = () => {
  const [loading, setLoading] = useState(false)
  const [plans, setPlans] = useState<Array<any>>([])
  const history = useHistory()

  // Paginação
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [paginationOn, setPaginationOn] = useState(true)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState('')
  const limit = 10

  const getPlans = async (numberPage = page, filtername = '') => {
    setLoading(true)

    try {
      const { data } = await api.get(
        `/payments/ms/v1/plans?page=${numberPage}&filter=${filtername}&limit=${limit}`
      )
      setNumberOfPages(data.total_page)
      setPlans(data.plans)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const handleSearch = () => {
    setPaginationOn(false)
    getPlans(1, filter)
    setPage(1)
  }

  useEffect(() => {
    if (numberOfPages === 1) {
      setPaginationOn(false)
    }
    getPlans()
  }, [page])

  return (
    <div>
      {loading && <Loading title="Carregando..." />}
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row className="mb-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="justify-content-start">
            <Link to="plano/novo">
              <Button color="secondary">
                <i className="fas fa-plus" />
              </Button>
            </Link>
          </Col>
          <Col sm="auto" className="d-flex justify-content-end">
            <Col xs="10">
              <Input
                type="text"
                id="filter"
                value={filter}
                placeholder="Filtrar pelo nome do plano"
                onChange={(e) => setFilter(e.target.value)}
                onKeyDown={(e) => handleEnterPress(e, handleSearch)}
              />
            </Col>
            <Col xs="auto">
              <Button
                color="secondary"
                onClick={() => {
                  setPaginationOn(false)
                  getPlans(1, filter)
                  setPage(1)
                }}
              >
                <i className="fas fa-search" />
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                onClick={() => {
                  if (page === 1) {
                    getPlans(1, '')
                  }
                  setPage(1)
                  setFilter('')
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </Col>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="d-flex justify-content-between mb-3">
                <Col sm="9" className="mb-2">
                  <h2 className="mb-0 text-black">Planos Cadastrados</h2>
                </Col>
              </CardHeader>
              {plans?.map((plano) => (
                <Col className="mb-4" key={plano?.id}>
                  <Col className="d-flex justify-content-between">
                    <h3 className="mb-3 text-black">{plano.title}</h3>
                    <span style={{ color: 'red' }}>{`R$${plano.amount}`}</span>
                  </Col>
                  <Col>
                    <p>
                      {`${plano.count_scheduling} consultas de especialista - ${plano.number_psychology} consultas de psicologia - ${plano.number_nutritionist} consultas de nutrição`}
                    </p>
                    <p>
                      {`${
                        plano.dependents_quantity === null
                          ? 0
                          : plano.dependents_quantity
                      } dependentes`}
                    </p>
                  </Col>
                  <Row className="d-flex justify-content-end m-0">
                    <Col sm="auto">
                      <Button onClick={() => history.push(`plano/novo`, plano)}>
                        Alterar
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                </Col>
              ))}
              <Col className="align-items-end">
                {paginationOn && (
                  <PaginationComponent
                    numberOfPages={numberOfPages}
                    page={page}
                    setPage={setPage}
                  />
                )}
              </Col>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}
export default Plans
