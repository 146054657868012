import React, { useState, useEffect } from 'react'
import { cpfCnpjMask } from 'js-essentials-functions'
import { Row, Table, Col, CardHeader, CardBody, Card } from 'reactstrap'
import api from 'configs/api'
import Loading from 'components/Loading'
import { useLocation } from 'react-router-dom'
import PaginationComponent from 'components/PaginationComponent'

const AdminsCompany = ({ company }: any) => {
  const [usersAdmin, setUsersAdmin] = useState<any>()

  const [loading, setLoading] = useState(false)

  // Paginação
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10

  const params: any = useLocation()

  const companyId =
    params.pathname.split('/')[params.pathname.split('/').length - 1]
  const getUsers = async () => {
    setLoading(true)
    try {
      const { data: users } = await api.get(
        `/company/ms/v1/rh/companies/${companyId}/users?page=${page}&limit=10`
      )

      setUsersAdmin(users.users)
      const totalPages = Math.ceil(users?.total / limit)

      setNumberOfPages(totalPages)
    } catch (err: any) {
      console.log('error', err?.response?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getUsers()
  }, [page])

  if (loading) return <Loading title="Aguarde..." />
  return (
    <div>
      <CardHeader
        className="bg-transparent border-0"
        style={{ minHeight: 100 }}
      >
        <Row className="mt-3 align-items-center d-flex justify-content-between">
          <Col sm="auto" className="mb-2">
            <h3 className="mb-0 text-black">{`${company?.legal_name} | Administradores`}</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="shadow">
        <Row className="mt-0">
          <div className="col">
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">name</th>
                    <th scope="col">email</th>
                    <th scope="col">cpf</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {usersAdmin?.map((user: any) => (
                    <tr key={user.id}>
                      <th scope="row">{user.id}</th>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{cpfCnpjMask(user.document || '')}</td>
                      <td>{user.status === 'active' ? 'Ativo' : 'Inativo'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        <PaginationComponent
          numberOfPages={numberOfPages}
          page={page}
          setPage={setPage}
        />
      </CardBody>
    </div>
  )
}

export default AdminsCompany
